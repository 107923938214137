export default {
  leftSidebarCollapsed: false,
  rightSidebarCollapsed: false,
  matches: [],
  filters: null,
  center: null,
  loading: false,
  mission: {},
  players: [],
  provider: "opta",
};
