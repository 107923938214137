import _ from "lodash";

function reduceSkills(skills, parentSkill) {
  return _.reduce(
    skills,
    (result, entry) => {
      const parent = _.pick(entry, ["id", "name"]);
      if (!Array.isArray(entry.children)) {
        if (parentSkill) parent.parent = _.pick(parentSkill, ["id", "name"]);
        return result.concat([parent]);
      }

      const children = reduceSkills(entry.children, entry);
      return result.concat(children);
    },
    []
  );
}

export default {
  players(state) {
    return _.get(state, "data.teams.home.players", []).concat(
      _.get(state, "data.teams.visiting.players", [])
    );
  },

  teams(state) {
    if (!state.data) return [];

    return [state.data.teams.home, state.data.teams.visiting];
  },

  coaches(state) {
    if (!state.data) return [];

    return [state.data.teams.home.coach, state.data.teams.visiting.coach];
  },

  tags(state) {
    if (Array.isArray(state.filteredTags)) {
      return _.sortBy(state.filteredTags, ["id"]);
    }

    if (!state.data || !Array.isArray(state.data.tags)) return [];

    return _(state.data.tags).sortBy(["id"]).reverse().value();
  },

  tagById(state) {
    if (!state.data || !Array.isArray(state.data.tags)) return [];

    return (id) => _.find(state.data.tags, ["id", id]);
  },

  skills(state) {
    let skills = state.goalkeeperMode
      ? _.get(state, "data.skills_portiere", [])
      : _.get(state, "data.skills", []);

    if (state.activeSetting && state.activeSetting.skill_categories) {
      skills = _.flatMap(
        state.activeSetting.skill_categories,
        (category) => category.skills
      );
    }

    if (!_.isEmpty(skills)) {
      skills = _.reduce(
        skills,
        (result, entry) => {
          const parent = _.omit(entry, ["children"]);
          return result.concat([parent, ...(entry.children || [])]);
        },
        []
      );
    }

    return skills;
  },

  childSkills(state) {
    if (!state.data) return [];

    if (state.goalkeeperMode) {
      return Array.isArray(state.data.skills_portiere)
        ? reduceSkills(state.data.skills_portiere)
        : [];
    }

    if (!Array.isArray(state.data.skills)) return [];

    return reduceSkills(state.data.skills);
  },

  categories(state) {
    if (state.activeSetting) {
      return _.get(state.activeSetting, "skill_categories", []);
    }

    if (state.goalkeeperMode) {
      return _.get(state.data, "skill_categories_portiere", []);
    }

    return _.get(state.data, "skill_categories", []);
  },

  newTagFieldSection(state) {
    if (!state.newTag) return null;

    return state.newTag.fieldSection || state.newTag.field_section;
  },

  newTagResult(state) {
    if (!state.newTag) return null;

    return state.newTag.result;
  },

  newTagType(state) {
    if (!state.newTag) return null;

    return state.newTag.type;
  },

  newTagSkillIds(state) {
    if (!state.newTag || !state.newTag.skills) return [];

    return state.newTag.skills.map((skill) => skill.id);
  },

  settings(state) {
    if (!state.data) return [];

    return state.data.settings || [];
  },

  settingById(state) {
    if (!state.data || !state.data.settings) return null;

    return (id) => _.find(state.data.settings, ["id", id]);
  },

  videoBookmarks(state) {
    if (!state.data || !state.data.video || !state.data.video.bookmarks)
      return [];

    return state.data.video.bookmarks;
  },

  currentPlayerId(state) {
    if (!state.data) return null;

    return state.data.player_id;
  },
};
