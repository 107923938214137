import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import "./plugins/bootstrap-vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Auth from "./lib/auth.js";
import i18n from "./i18n";

Vue.use(VueAxios, axios);

Vue.config.productionTip = false;

axios.interceptors.request.use(
  function (config) {
    config.url = store.state.apiEndPoint + config.url;
    if (Auth.checkLocalStorage()) {
      config.headers["tokenauth"] = "Bearer " + Auth.user.api_token;
    } else {
      config.headers["tokenauth"] = "";
    }
    return config;
  },
  function (error) {
    console.log(error);
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    if (response.status == 401) {
      localStorage.removeItem("user");

      Auth.user = {};
      Auth.authenticated = false;

      router.replace("/login");
    }
    return response;
  },
  function (error) {
    console.log(error);
    if (error.response && error.response.status == 401) {
      localStorage.removeItem("user");

      Auth.user = {};
      Auth.authenticated = false;

      router.replace("/login");
    }
    return Promise.reject(error);
  }
);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
