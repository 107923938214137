<template>
  <data-table
    api-url="/admin/competizioni"
    :columns="columns"
    :canDelete="false"
    :importa="true"
    :defaultFilter="false"
    @importData="importCompetition"
  >
    <template slot="filters">
      <b-form-input
        v-model="filters.paese"
        placeholder="Paese"
        class="inline mr-3"
      />
      <b-form-input
        v-model="filters.competizione"
        placeholder="Nome"
        class="inline mr-3"
      />
    </template>
    <template slot="import-modal-body">
      <div align="center" style="width: 100%">
        <h5>
          Importazione <strong>{{ labelComp }}</strong
          ><span v-if="loading">...attendere</span>
        </h5>
      </div>
      <div v-if="loading" align="center" style="width: 100%">
        <img :src="loadingImg" style="width: 150px" />
      </div>
      <div v-if="!loading" align="center" style="width: 100%">
        <h5>{{ message }}</h5>
      </div>
    </template>
  </data-table>
</template>

<script>
import axios from "axios";
import DataTable from "./DataTable.vue";
import loadingImg from "@/assets/images/loading.gif";

export default {
  name: "AdminCompetizioni",
  components: { DataTable },

  data() {
    return {
      columns: [
        { key: "id", label: "ID", sortable: true },
        { key: "name", label: "Nome", sortable: true },
        { key: "paese", label: "Paese", sortable: true },
        //  { key: "actions", label: "Azioni" },
      ],
      loading: false,
      message: "",
      labelComp: "",
      competizioni: [],
      filters: {
        paese: "",
        competizione: "",
      },
      loadingImg,
    };
  },

  methods: {
    importCompetition(row) {
      this.labelComp = row.item.nome;
      this.loading = true;
      axios
        .get("/admin/competizioni/import/" + row.item.id)
        .then((res) => {
          this.message = res.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },

  watch: {
    filters: {
      handler() {
        this.$root.$emit("data-table::filter", this.filters);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.pac-container {
  z-index: 1051 !important;
}
</style>
