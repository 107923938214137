<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import Auth from "@/lib/auth.js";

export default {
  data() {
    return {
      dummy: "",
    };
  },
  components: {},
  computed: {
    auth: function () {
      return Auth;
    },
    user: function () {
      return Auth.user;
    },
  },
};
</script>

<style lang="scss">
@import "./assets/css/style.scss";

#app {
  font-family: "DINPro", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*  text-align: center; */
}
</style>
