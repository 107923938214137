import Vue from "vue";
import VueRouter from "vue-router";
import Auth from "../lib/auth.js";

import Geomatch from "@/views/geomatch/Geomatch.vue";
import GeomatchHome from "@/views/geomatch/Home.vue";
import GeomatchAdmin from "@/views/geomatch/admin/Admin.vue";
import Stadiums from "@/views/geomatch/admin/Stadiums.vue";
import Matches from "@/views/geomatch/admin/Matches.vue";
import Teams from "@/views/geomatch/admin/Teams.vue";
import Competizioni from "@/views/geomatch/admin/Competizioni.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    //  redirect: "/scouting/players",
    redirect: "/home",
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login.vue"),
  },
  {
    path: "/",
    name: "Main",
    component: () => import("@/views/Main.vue"),
    children: [
      {
        path: "/profile",
        name: "Profile",
        component: () => import("@/views/Profile.vue"),
        meta: {
          label: "Profile",
          auth: true,
          menu: false,
          subMenu: [],
        },
      },
      {
        path: "/admin/users",
        name: "AdminUsers",
        component: () => import("@/views/admin/Users.vue"),
        meta: {
          module: "admin",
          label: "Users",
          auth: true,
          menu: false,
          permesso: "admin_users",
          subMenu: [],
        },
      },
      {
        path: "/admin/opta/setup",
        name: "OptaPlayerSetup",
        component: () => import("@/views/admin/OptaSetup.vue"),
        meta: {
          module: "admin",
          label: "Opta player setup",
          auth: true,
          menu: false,
          permesso: "opta_player_setup",
          subMenu: [],
        },
      },
      {
        path: "/admin/micro/player/setup",
        name: "MicroPlayerSetup",
        component: () => import("@/views/admin/MicroPlayerSetup.vue"),
        meta: {
          module: "admin",
          label: "Micro player setup",
          auth: true,
          menu: false,
          permesso: "opta_player_setup",
          subMenu: [],
        },
      },
      {
        path: "/admin/micro/player/basic/skill/setup",
        name: "MicroPlayerBasicSkillSetup",
        component: () => import("@/views/admin/MicroPlayerBasicSkillSetup.vue"),
        meta: {
          module: "admin",
          label: "Micro player basic skill setup",
          auth: true,
          menu: false,
          permesso: "opta_player_setup",
          subMenu: [],
        },
      },
      {
        path: "/admin/role/weight/list/setup",
        name: "RoleWeightListSetup",
        component: () => import("@/views/admin/RoleWeightListSetup.vue"),
        meta: {
          module: "admin",
          label: "Role weight list setup",
          auth: true,
          menu: false,
          permesso: "opta_player_setup",
          subMenu: [],
        },
      },
      {
        path: "/admin/correlation/weight/setup",
        name: "CorrelationWeightSetup",
        component: () => import("@/views/admin/CorrelationWeightSetup.vue"),
        meta: {
          module: "admin",
          label: "Correlation weight setup",
          auth: true,
          menu: false,
          permesso: "correlation_weight_setup",
          subMenu: [],
        },
      },
      {
        path: "/admin/player/index/game",
        name: "PlayerIndexGame",
        component: () => import("@/views/admin/PlayerIndexGame.vue"),
        meta: {
          module: "admin",
          label: "Player index game",
          auth: true,
          menu: false,
          permesso: "opta_player_setup",
          subMenu: [],
        },
      },
      {
        path: "/admin/principles/player/setup",
        name: "PrinciplesPlayerSetup",
        component: () => import("@/views/admin/PrinciplesPlayerSetup.vue"),
        meta: {
          module: "admin",
          label: "Principles player setup",
          auth: true,
          menu: false,
          permesso: "opta_player_setup",
          subMenu: [],
        },
      },
      {
        path: "/admin/opta/team/setup",
        name: "OptaTeamSetup",
        component: () => import("@/views/admin/OptaTeamSetup.vue"),
        meta: {
          module: "admin",
          label: "Opta coach setup",
          auth: true,
          menu: false,
          permesso: "opta_team_setup",
          subMenu: [],
        },
      },
      {
        path: "/admin/principles/coach/setup",
        name: "PrinciplesCoachSetup",
        component: () => import("@/views/admin/PrinciplesCoachSetup.vue"),
        meta: {
          module: "admin",
          label: "Principles coach setup",
          auth: true,
          menu: false,
          permesso: "opta_team_setup",
          subMenu: [],
        },
      },
      {
        path: "/admin/principles/game/setup",
        name: "PrinciplesGameSetup",
        component: () => import("@/views/admin/PrinciplesGameSetup.vue"),
        meta: {
          module: "admin",
          label: "Principles game setup",
          auth: true,
          menu: false,
          permesso: "opta_player_setup",
          subMenu: [],
        },
      },
      {
        path: "/home",
        name: "Home",
        component: () => import("@/views/Home.vue"),
        meta: {
          module: "scouting",
          label: "Home",
          auth: true,
          menu: true,
        },
      },
      {
        path: "/scouting/players",
        name: "ScoutingPlayers",
        component: () => import("@/views/scouting/Players.vue"),
        meta: {
          module: "scouting",
          label: "Players",
          auth: true,
          menu: true,
          permesso: "players",
        },
      },
      {
        path: "/scouting/new/player",
        name: "ScoutingNewPlayer",
        component: () => import("@/views/scouting/PlayerAdd.vue"),
        meta: {
          module: "scouting",
          label: "Player",
          auth: true,
          menu: false,
          permesso: "player_add",
        },
      },
      {
        path: "/scouting/new/player/:ln/:fn",
        name: "ScoutingNewPlayerFromOpta",
        component: () => import("@/views/scouting/PlayerAdd.vue"),
        meta: {
          module: "scouting",
          label: "Player",
          auth: true,
          menu: false,
          permesso: "player_add",
        },
      },
      {
        path: "/scouting/view/player/:id",
        name: "ScoutingViewPlayer",
        component: () => import("@/views/scouting/Player.vue"),
        meta: {
          module: "scouting",
          label: "Player card",
          auth: true,
          menu: false,
          permesso: "opta",
          //  subMenu: ["ScoutingEditPlayer", "ScoutingEvalPlayer"],
          subMenu: [],
        },
      },
      {
        path: "/scouting/edit/player/:id",
        name: "ScoutingEditPlayer",
        component: () => import("@/views/scouting/PlayerEdit.vue"),
        meta: {
          module: "scouting",
          label: "Data edit",
          auth: true,
          menu: false,
          permesso: "player_edit",
          //  subMenu: ["ScoutingViewPlayer", "ScoutingEvalPlayer"],
          subMenu: [],
        },
      },
      {
        path: "/scouting/eval/player/:id",
        name: "ScoutingEvalPlayer",
        component: () => import("@/views/scouting/PlayerEval.vue"),
        meta: {
          module: "scouting",
          label: "Evaluation",
          auth: true,
          menu: false,
          permesso: "player_eval",
          //  subMenu: ["ScoutingViewPlayer", "ScoutingEditPlayer"],
          subMenu: [],
        },
      },
      {
        path: "/scouting/videotag/player/:id",
        name: "ScoutingVideotagPlayer",
        component: () => import("@/views/scouting/PlayerVideotag.vue"),
        meta: {
          module: "scouting",
          label: "Videotag",
          auth: true,
          menu: false,
          permesso: "player_videotag",
          subMenu: [],
        },
      },
      {
        path: "/scouting/player/index/games/:id",
        name: "ScoutingPlayerIndexGames",
        component: () => import("@/views/scouting/PlayerIndexGames.vue"),
        meta: {
          module: "scouting",
          label: "Games",
          auth: true,
          menu: false,
          permesso: "opta",
          subMenu: [],
        },
      },
      {
        path: "/scouting/player/index/game/:id/:game_id",
        name: "ScoutingPlayerIndexGame",
        component: () => import("@/views/scouting/PlayerIndexGame.vue"),
        meta: {
          module: "scouting",
          label: "Game",
          auth: true,
          menu: false,
          permesso: "opta",
          subMenu: [],
        },
      },
      {
        path: "/scouting/matches",
        name: "ScoutingMatches",
        component: () => import("@/views/scouting/Matches.vue"),
        meta: {
          module: "scouting",
          label: "Matches",
          auth: true,
          menu: true,
          permesso: "matches",
        },
      },
      {
        path: "/scouting/coaches",
        name: "ScoutingCoaches",
        component: () => import("@/views/scouting/Coaches.vue"),
        meta: {
          module: "scouting",
          label: "Coaches",
          auth: true,
          menu: true,
          permesso: "coaches",
        },
      },
      /*    {
        path: "/scouting/new/coach",
        name: "ScoutingNewCoach",
        component: () => import("@/views/scouting/CoachAdd.vue"),
        meta: {
          module: "scouting",
          label: "Coach",
          auth: true,
          menu: true,
          permesso: "coach_add"
        }
      },  */
      {
        path: "/scouting/view/coach/:id",
        name: "ScoutingViewCoach",
        component: () => import("@/views/scouting/Coach.vue"),
        meta: {
          module: "scouting",
          label: "Coach card",
          auth: true,
          menu: false,
          permesso: "opta_coach",
          subMenu: ["ScoutingEditCoach", "ScoutingVideotagCoach"],
        },
      },
      {
        path: "/scouting/edit/coach/:id",
        name: "ScoutingEditCoach",
        component: () => import("@/views/scouting/CoachEdit.vue"),
        meta: {
          module: "scouting",
          label: "Data edit",
          auth: true,
          menu: false,
          permesso: "coach_edit",
          subMenu: ["ScoutingViewCoach", "ScoutingVideotagCoach"],
        },
      },
      {
        path: "/scouting/videotag/coach/:id",
        name: "ScoutingVideotagCoach",
        component: () => import("@/views/scouting/CoachVideotag.vue"),
        meta: {
          module: "scouting",
          label: "Videotag",
          auth: true,
          menu: false,
          permesso: "coach_videotag",
          subMenu: ["ScoutingViewCoach", "ScoutingEditCoach"],
        },
      },
      {
        path: "/scouting/videotag",
        name: "ScoutingVideotag",
        component: () => import("@/views/scouting/Videotag.vue"),
        meta: {
          module: "scouting",
          label: "Videotag",
          auth: true,
          menu: true,
          permesso: "videotag",
        },
      },
      {
        path: "/scouting/teams",
        name: "ScoutingTeams",
        component: () => import("@/views/scouting/Teams.vue"),
        meta: {
          module: "scouting",
          label: "Teams",
          auth: true,
          menu: true,
          permesso: "teams",
        },
      },
      {
        path: "/scouting/team/:id",
        name: "ScoutingTeam",
        component: () => import("@/views/scouting/Team.vue"),
        meta: {
          module: "scouting",
          label: "Team",
          auth: true,
          menu: false,
          permesso: "teams",
          subMenu: ["ScoutingTeamGames"],
        },
      },
      {
        path: "/scouting/milan/games/131",
        name: "ScoutingTeamGamesMilan",
        component: () => import("@/views/scouting/TeamGames.vue"),
        meta: {
          module: "scouting",
          label: "Games Milan",
          auth: true,
          menu: true,
          permesso: "teams_milan",
          subMenu: [],
        },
      },
      {
        path: "/scouting/team/games/:id",
        name: "ScoutingTeamGames",
        component: () => import("@/views/scouting/TeamGames.vue"),
        meta: {
          module: "scouting",
          label: "Games",
          auth: true,
          menu: false,
          permesso: "teams",
          subMenu: ["ScoutingTeam"],
        },
      },
      {
        path: "/scouting/team/games/131/:game_id",
        name: "ScoutingTeamGameMilan",
        component: () => import("@/views/scouting/TeamGame.vue"),
        meta: {
          module: "scouting",
          label: "Game Milan",
          auth: true,
          menu: false,
          permesso: "teams_milan",
          subMenu: ["ScoutingTeamGamesMilan"],
        },
      },
      {
        path: "/scouting/team/game/:id/:game_id",
        name: "ScoutingTeamGame",
        component: () => import("@/views/scouting/TeamGame.vue"),
        meta: {
          module: "scouting",
          label: "Game",
          auth: true,
          menu: false,
          permesso: "opta",
          subMenu: ["ScoutingTeam", "ScoutingTeamGames"],
        },
      },
      {
        path: "/scouting/agenda",
        name: "ScoutingAgenda",
        component: () => import("@/views/scouting/Agenda.vue"),
        meta: {
          module: "scouting",
          label: "Agenda",
          auth: true,
          menu: true,
          permesso: "agenda",
        },
      },
      {
        path: "/geomatch",
        // name: "Geomatch",
        component: Geomatch,
        meta: {
          module: "scouting",
          label: "Geomatch",
          auth: true,
          menu: true,
          permesso: "geomatch",
        },
        children: [
          { path: "", component: GeomatchHome },
          {
            path: "admin",
            component: GeomatchAdmin,
            children: [
              { path: "", component: Stadiums },
              { path: "matches", component: Matches },
              { path: "teams", component: Teams },
              { path: "competizioni", component: Competizioni },
            ],
          },
        ],
      },
      {
        path: "/scouting/costi",
        name: "ScoutingCosti",
        component: () => import("@/views/scouting/Costi.vue"),
        meta: {
          module: "scouting",
          label: "Costi",
          auth: true,
          menu: true,
          permesso: "costi",
        },
      },
      {
        path: "/scouting/statistics",
        name: "ScoutingStatistics",
        component: () => import("@/views/scouting/Statistics.vue"),
        meta: {
          module: "scouting",
          label: "Statistics",
          auth: true,
          menu: true,
          permesso: "statistics",
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.auth)) {
    if (!Auth.checkLocalStorage()) {
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
    } else {
      if (to.matched.some((record) => record.meta.permesso)) {
        if (
          Auth.user &&
          Auth.user.policy_required &&
          !Auth.user.policy_accepted
        ) {
          next({
            path: "/home",
            query: { redirect: to.fullPath },
          });
        } else if (!Auth.isUserEnable(to.matched[1].meta.permesso)) {
          next({
            path: "/login",
            query: { redirect: to.fullPath },
          });
        } else next();
      } else next();
    }
  } else {
    next();
  }
});

export default router;
