export default {
  data: null,
  error: null,
  currentTime: null,
  seekTo: null,
  selectedSetting: null,
  activeSetting: null,
  playing: false,
  fullScreen: false,
  sidebarHidden: false,
  editorsHidden: false,
  isRecording: false,
  recording: {},
  currentPlaybackRate: 1,
  goalkeeperMode: false,
  editor: false,
  editorShown: null,
  canvas: {
    id: null,
    color: {
      hex: "#000000",
      rgba: {
        r: 0,
        g: 0,
        b: 0,
        a: 1,
      },
    },
    drawingMode: false,
    lineWidth: 1,
    export: "",
    image: "",
    wavy: false,
    dashed: false,
    width: 1280,
    height: 720,
    background: null,
  },
  activeObject: null,
  canvasObjects: null,
};
