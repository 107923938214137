import moment from "moment";

const DATETIME_FORMAT = "DD/MM/YYYY HH:mm";

export default {
  methods: {
    matchDateFormat(date) {
      return moment(date).format(DATETIME_FORMAT);
    },
  },
};
