<template>
  <b-modal
    id="add-mission-modal"
    ref="add-mission-modal"
    title="Aggiungi missione"
    centered
    size="lg"
  >
    <b-row v-if="mission.match">
      <b-col>
        <h5>Partita</h5>
        <strong>{{ mission.match.casa }} - {{ mission.match.trasferta }}</strong
        ><br />
        {{ matchDateFormat(mission.match.data) }}
      </b-col>
      <b-col>
        <b-form-group label="Intervallo di viaggio:">
          <b-row class="mb-2">
            <b-col cols="8">
              <b-input-group prepend="Dal">
                <b-form-datepicker
                  v-model="viaggio_dal_data"
                  :dark="true"
                  :date-format-options="{
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                  }"
                ></b-form-datepicker>
              </b-input-group>
            </b-col>
            <b-col cols="4">
              <b-form-timepicker v-model="viaggio_dal_ora"></b-form-timepicker>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="8">
              <b-input-group prepend="Al">
                <b-form-datepicker
                  v-model="viaggio_al_data"
                  :dark="true"
                  :date-format-options="{
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                  }"
                ></b-form-datepicker>
              </b-input-group>
            </b-col>
            <b-col cols="4">
              <b-form-timepicker v-model="viaggio_al_ora"></b-form-timepicker>
            </b-col>
          </b-row>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col>
        <h5>Osservatori</h5>
        <b-form-checkbox-group
          v-model="selectedOsservatori"
          :options="osservatoriCheckbox"
        >
        </b-form-checkbox-group>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col>
        <h5>Note</h5>
        <b-form-textarea v-model="notes" rows="3" />
      </b-col>
    </b-row>
    <b-button
      type="button"
      variant="primary"
      @click="aggiungiMissione"
      class="mt-3"
      :disabled="disabled"
      >Salva</b-button
    >
  </b-modal>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import matchesMixins from "@/mixins/matches.js";
import Auth from "@/lib/auth.js";

export default {
  name: "MissionModal",
  mixins: [matchesMixins],

  data() {
    return {
      notes: "",
      selectedOsservatori: [],
      osservatori: [],
      disabled: false,
      errors: [],
      viaggio_dal_data: null,
      viaggio_dal_ora: null,
      viaggio_al_data: null,
      viaggio_al_ora: null,
    };
  },

  computed: {
    auth: function () {
      return Auth;
    },
    ...mapState("geomatch", ["mission", "filters"]),

    /* tripDates() {
      let result = "";

      if (this.filters && this.filters.from) {
        result += this.matchDateFormat(
          `${this.filters.from.date} ${this.filters.from.time}`
          );
      }

      if (this.filters && this.filters.to) {
        result += ` - ${this.matchDateFormat(
          `${this.filters.to.date} ${this.filters.to.time}`
          )}`;
      }

      return result;
    }, */

    osservatoriCheckbox() {
      return this.osservatori.map((item) => ({
        text: item.nomeCompleto,
        value: item.id,
      }));
    },
  },

  mounted() {
    if (this.auth.isUserEnable("mission_add")) {
      axios
        .get("/osservatori")
        .then((res) => {
          this.osservatori = res.data;
        })
        .catch(() => {});
    }
    this.viaggio_dal_data = this.filters.from.date;
    this.viaggio_dal_ora = this.filters.from.time;
    this.viaggio_al_data = this.filters.to.date;
    this.viaggio_al_ora = this.filters.to.time;
  },
  methods: {
    aggiungiMissione() {
      this.disabled = true;
      var missione = {
        tipo: "partita",
        scadenza: this.mission.match.data.substr(0, 10),
        osservato: null,
        osservatori: this.selectedOsservatori,
        wyscout_partita_id: this.mission.match.wyscout_id,
        partita: this.mission.match.casa + " - " + this.mission.match.trasferta,
        partita_id: this.mission.match.id,
        competizione: this.mission.match.competizione,
        data_partita: this.mission.match.data.substr(0, 10),
        ora_partita: this.mission.match.data.substr(11, 5),
        evento: "partita",
        luogo: "stadio",
        viaggio_dal_data: this.viaggio_dal_data,
        viaggio_dal_ora: this.viaggio_dal_ora,
        viaggio_al_data: this.viaggio_al_data,
        viaggio_al_ora: this.viaggio_al_ora,
        nota: this.notes,
        newscout: 1,
      };
      this.$http.post("/scouting/add/missione", missione).then(
        (response) => {
          this.disabled = false;
          this.selectedOsservatori = [];
          this.$store.commit("msgPush", {
            msg: response.data.message,
            status: 1,
          });
          this.$refs["add-mission-modal"].hide();
        },
        (response) => {
          if (response.status == 422) {
            this.disabled = true;
            this.errors = response.data;
            for (var index in this.errors) {
              this.$store.commit("msgPush", {
                msg: this.errors[index],
                status: 0,
              });
            }
          }
          // error callback
        }
      );
    },
  },
};
</script>
