<template>
  <data-table
    api-url="/stadi"
    :columns="columns"
    :editFormData="stadiumData"
    :createFormData="newStadiumData"
    :defaultFilter="false"
    @showModal="onShowModal"
    @editError="editError"
  >
    <template slot="filters">
      <b-form-input
        v-model="filters.stadium"
        placeholder="Stadio"
        class="inline mr-3"
      />
      <b-form-input
        v-model="filters.country"
        placeholder="Paese"
        class="inline mr-3"
      />
      <b-form-input
        v-model="filters.city"
        placeholder="Città"
        class="inline mr-3"
      />
      <!--b-form-input
        v-model="filters.team"
        placeholder="Squadra"
        class="inline mr-3"
      /-->
    </template>

    <template slot="edit-modal-body">
      <b-form-group label="Nome">
        <b-form-input
          type="text"
          placeholder="Nome"
          v-model="stadiumData.nome"
        />
      </b-form-group>

      <!--b-form-group label="Indirizzo">
        <b-form-input type="text" placeholder="Indirizzo" ref="autocomplete" />
      </b-form-group-->
      <div>Indirizzo</div>
      <input
        class="form-control"
        ref="autocomplete"
        placeholder="Indirizzo"
        type="text"
        style="width: 100%"
      />

      <b-form-group label="Latitudine">
        <b-form-input
          type="text"
          placeholder="Latitudine"
          v-model="stadiumData.lat"
        />
      </b-form-group>

      <b-form-group label="Longitudine">
        <b-form-input
          type="text"
          placeholder="Longitudine"
          v-model="stadiumData.lng"
        />
      </b-form-group>
    </template>

    <template slot="create-modal-body">
      <b-form-group label="Nome">
        <b-form-input
          type="text"
          placeholder="Nome"
          v-model="newStadiumData.nome"
        />
      </b-form-group>

      <!--b-form-group label="Indirizzo">
        <b-form-input
          type="text"
          placeholder="Indirizzo"
          ref="createAutocomplete"
        />
      </b-form-group-->
      <div>Indirizzo</div>
      <input
        class="form-control"
        ref="createAutocomplete"
        placeholder="Indirizzo"
        type="text"
        style="width: 100%"
      />

      <b-form-group label="Latitudine">
        <b-form-input
          type="text"
          placeholder="Latitudine"
          v-model="newStadiumData.lat"
        />
      </b-form-group>

      <b-form-group label="Longitudine">
        <b-form-input
          type="text"
          placeholder="Longitudine"
          v-model="newStadiumData.lng"
        />
      </b-form-group>
    </template>
  </data-table>
</template>

<script>
import DataTable from "./DataTable.vue";

export default {
  name: "Stadiums",
  components: { DataTable },

  data() {
    return {
      columns: [
        { key: "id", label: "ID", sortable: true },
        { key: "name", label: "Nome", sortable: true },
        { key: "city", label: "Città", sortable: true },
        { key: "address", label: "Indirizzo", sortable: true },
        { key: "paese", label: "Paese", sortable: true },
        /*  {
          key: "squadre",
          label: "squadre",
          formatter: (value) => value.map((item) => item.name).join(", "),
        }, */
        { key: "lat", label: "Latitudine", sortable: true },
        { key: "lng", label: "Longitudine", sortable: true },
        //  { key: "actions", label: "Azioni" },
      ],
      stadiumData: {
        nome: "",
        lat: "",
        lng: "",
      },
      newStadiumData: {
        nome: "",
        lat: "",
        lng: "",
      },
      filters: {
        stadium: "",
        country: "",
        city: "",
        team: "",
      },
    };
  },

  methods: {
    onShowModal(row) {
      this.stadiumData.nome = row.item.nome;
      this.stadiumData.lat = row.item.lat;
      this.stadiumData.lng = row.item.lng;
      this.$refs.autocomplete.value = "";

      this.newStadiumData.nome = "";
      this.newStadiumData.lat = "";
      this.newStadiumData.lng = "";
      this.$refs.createAutocomplete.value = "";
    },

    editError(err) {
      if (err.response && err.response.data.error) {
        this.$store.commit(ALERTS_PUSH, {
          status: 0,
          msg: err.response.data.error,
        });
      }
    },

    fillLatLng() {
      const place = this.autocomplete.getPlace();

      if (place.geometry && place.geometry.location) {
        this.stadiumData.lat = place.geometry.location.lat();
        this.stadiumData.lng = place.geometry.location.lng();
      }
    },

    fillLatLngInCreate() {
      const place = this.createAutocomplete.getPlace();

      if (place.geometry && place.geometry.location) {
        this.newStadiumData.lat = place.geometry.location.lat();
        this.newStadiumData.lng = place.geometry.location.lng();
      }
    },
  },

  watch: {
    filters: {
      handler() {
        this.$root.$emit("data-table::filter", this.filters);
      },
      deep: true,
    },
  },

  mounted() {
    this.autocomplete = new window.google.maps.places.Autocomplete(
      this.$refs.autocomplete,
      { types: ["geocode"] }
    );
    this.createAutocomplete = new window.google.maps.places.Autocomplete(
      this.$refs.createAutocomplete,
      { types: ["geocode"] }
    );
    this.autocomplete.addListener("place_changed", this.fillLatLng);
    this.createAutocomplete.addListener(
      "place_changed",
      this.fillLatLngInCreate
    );
  },
};
</script>

<style lang="scss">
.pac-container {
  z-index: 1051 !important;
}
</style>
