<template>
  <nav :class="['sidebar', `sidebar-${side}`, collapsed ? 'collapsed' : '']">
    <slot name="sidebar-header" />

    <div class="sidebar-content">
      <slot></slot>
    </div>

    <slot name="sidebar-footer" />
  </nav>
</template>

<script>
export default {
  name: "Sidebar",

  props: {
    collapsed: {
      type: Boolean,
      default: false,
    },

    side: {
      type: String,
      default: "left",
    },
  },
};
</script>

<style lang="scss" scoped>
$sidebar-width: 356px;
$sidebar-height: 100%;
$sidebar-shadow-color: #cccccc;
$sidebar-shadow-width: 10px;
$sidebar-padding: 20px;

.sidebar {
  min-width: $sidebar-width;
  max-width: $sidebar-width;
  height: $sidebar-height;
  min-height: $sidebar-height;
  max-height: $sidebar-height;
  overflow: auto;
  padding: $sidebar-padding;

  &.sidebar-left {
    order: -1;
    box-shadow: 2px 0px 5px 0px $sidebar-shadow-color;

    &.collapsed {
      margin-left: -($sidebar-width + $sidebar-shadow-width);
      display: none;
    }
  }

  &.sidebar-right {
    order: 2;
    box-shadow: -2px 0px 5px 0px $sidebar-shadow-color;

    &.collapsed {
      margin-right: -($sidebar-width + $sidebar-shadow-width);
      display: none;
    }
  }

  .sidebar-header {
    padding: 20px;
    background: #6d7fcc;
  }

  .sidebar-content {
    height: 100%;
  }
}

@media (max-width: 992px) {
  .sidebar {
    &.sidebar-left {
      margin-left: -($sidebar-width + $sidebar-shadow-width);
      display: none;

      &.collapsed {
        margin-left: 0;
        display: block;
      }
    }

    &.sidebar-right {
      margin-right: -($sidebar-width + $sidebar-shadow-width);
      display: none;

      &.collapsed {
        margin-right: 0;
        display: block;
      }
    }
  }
}
</style>
