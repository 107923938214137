<template>
  <div class="wrapper">
    <results :collapsed="leftSidebarCollapsed" />

    <v-map />

    <filters :collapsed="rightSidebarCollapsed" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import Results from "./Results.vue";
import Filters from "./Filters.vue";
import Map from "./Map.vue";

export default {
  name: "GeomatchHome",
  components: {
    Results,
    Filters,
    "v-map": Map,
  },

  computed: mapState("geomatch", [
    "leftSidebarCollapsed",
    "rightSidebarCollapsed",
  ]),
};
</script>
<style lang="scss" scoped>
.wrapper {
  max-height: 100%;
}
</style>
