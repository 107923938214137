import Vue from "vue";
import Vuex from "vuex";
import videotag from "./videotag";
import geomatch from "./geomatch";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    //apiEndPoint: "http://localhost:8888",
    //  apiEndPoint: "https://dominance.stdfootball.ovh",
    apiEndPoint: "https://domi-be.chimeralabs.net",
    //apiEndPoint: "https://domi-be-contabo.chimeralabs.net",
    messages: [],
    extraValueIndex: false,
  },
  mutations: {
    msgRemove(state, message) {
      var i = state.messages.indexOf(message);
      state.messages.splice(i, 1);
    },

    msgPush(state, message) {
      state.messages.push(message);
    },

    toggleIndex(state) {
      Vue.set(state, "extraValueIndex", !state.extraValueIndex);
    },
  },
  actions: {},
  modules: {
    videotag,
    geomatch,
  },
});
