<template>
  <div>
    <b-row class="row-table">
      <b-col>
        <b-table
          ref="table"
          hover
          fixed
          bordered
          outlined
          :items="fetch"
          :fields="columns"
          :busy.sync="isBusy"
          :sort-by.sync="sortBy"
          :sort-desc="sortDesc"
          :current-page="currentPage"
          :per-page="perPage"
          :filter="filter"
        >
          <!--template v-if="!noActions" #cell(actions)="row">
            <b-btn
              size="sm"
              class="mr-2"
              v-if="!!editFormData"
              @click="showEditModal(row)"
              >Modifica</b-btn
            >
            <b-btn
              size="sm"
              v-if="canDelete"
              variant="primary"
              @click="showDeleteModal(row)"
              >Elimina</b-btn
            >
            <b-btn
              size="sm"
              v-if="importa"
              variant="primary"
              @click="importData(row)"
              >Importa</b-btn
            >
          </template-->
        </b-table>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-pagination
          :total-rows="totalRows"
          :per-page="perPage"
          v-model="currentPage"
        />
        <b-form-input
          v-if="defaultFilter"
          v-model="filter"
          placeholder="Filtra"
          class="inline mr-3"
        />
        <slot name="filters" />
      </b-col>
      <!--b-col v-if="!!createFormData" class="new-button">
        <b-btn variant="success" v-b-modal="newModalId">Nuovo</b-btn>
      </b-col-->
    </b-row>

    <b-modal
      v-if="!!editFormData"
      :id="modalId"
      centered
      size="lg"
      @ok="edit"
      title="Modifica"
      :static="true"
    >
      <slot name="edit-modal-body" :row="currentRow" />
    </b-modal>

    <b-modal
      v-if="canDelete"
      :id="deleteModalId"
      centered
      @ok="deleteRecord"
      title="Elimina"
      ok-title="Si"
      cancel-title="No"
    >
      Sei sicuro di voler eliminare questo record?
    </b-modal>

    <b-modal
      v-if="!!createFormData"
      :id="newModalId"
      centered
      size="lg"
      @ok="create"
      title="Nuovo"
      :static="true"
    >
      <slot name="create-modal-body" />
    </b-modal>
    <b-modal
      v-if="!!importa"
      :id="importModalId"
      centered
      title="Importa"
      size="lg"
      ok-only
      ok-title="Chiudi"
    >
      <slot name="import-modal-body" :row="currentRow" />
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import _ from "lodash";

export default {
  name: "DataTable",

  props: {
    apiUrl: {
      type: String,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
      default: () => [],
    },
    noActions: {
      type: Boolean,
      default: false,
    },
    editFormData: {
      type: Object,
    },
    createFormData: {
      type: Object,
    },
    canDelete: {
      type: Boolean,
      default: true,
    },
    defaultFilter: {
      type: Boolean,
      default: true,
    },
    importa: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      sortBy: "id",
      sortDesc: false,
      isBusy: false,
      totalRows: 0,
      perPage: 15,
      currentPage: 1,
      filter: "",
      modalId: `modal-${new Date().getTime()}`,
      tableId: `table-${new Date().getTime()}`,
      deleteModalId: `modal-${new Date().getTime()}-delete`,
      newModalId: `modal-${new Date().getTime()}-create`,
      importModalId: `modal-${new Date().getTime()}-import`,
      currentRow: null,
      additionalFilters: null,
    };
  },

  methods: {
    fetch(ctx) {
      return axios
        .get(this.apiUrl, {
          params: {
            page: ctx.currentPage,
            per_page: ctx.perPage || 15,
            sort_by: ctx.sortBy,
            sort_direction: ctx.sortDesc ? "DESC" : "ASC",
            filter: ctx.filter,
            additional_filters: this.additionalFilters,
          },
        })
        .then((res) => {
          if (!res.data) return [];

          const data = res.data.data || [];
          this.totalRows = res.data.total;
          this.currentPage = res.data.current_page;

          return data;
        })
        .catch(() => []);
    },

    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData);
    },

    onChangePage(page) {
      this.$refs.vuetable.changePage(page);
    },

    showEditModal(row) {
      this.$emit("showModal", row);
      this.currentRow = row;
      this.$root.$emit("bv::show::modal", this.modalId);
    },

    showDeleteModal(row) {
      this.currentRow = row;
      this.$root.$emit("bv::show::modal", this.deleteModalId);
    },

    importData(row) {
      this.$emit("importData", row);
      this.currentRow = row;
      this.$root.$emit("bv::show::modal", this.importModalId);
    },

    edit() {
      if (!this.currentRow) return;

      if (this.$listeners && this.$listeners.edit) {
        this.$emit("edit", this.currentRow);
        return;
      }

      if (!this.editFormData) return;

      axios
        .put(`${this.apiUrl}/${this.currentRow.item.id}`, {
          data: this.editFormData,
        })
        .then(() => {
          this.currentRow = null;
          this.$refs.table.refresh();
        })
        .catch((err) => {
          this.$emit("editError", err);
          this.currentRow = null;
          this.$refs.table.refresh();
        });
    },

    deleteRecord() {
      if (!this.currentRow) return;

      if (this.$listeners && this.$listeners.deleteRecord) {
        this.$emit("deleteRecord", this.currentRow);
        return;
      }

      axios
        .delete(`${this.apiUrl}/${this.currentRow.item.id}`)
        .then(() => {
          this.currentRow = null;
          this.$refs.table.refresh();
        })
        .catch((err) => {
          this.$emit("deleteError", err);
          this.currentRow = null;
          this.$refs.table.refresh();
        });
    },

    create() {
      if (this.$listeners && this.$listeners.createRecord) {
        this.$emit("createRecord");
        return;
      }

      if (!this.createFormData) return;

      axios
        .post(this.apiUrl, {
          data: this.createFormData,
        })
        .then(() => {
          this.$refs.table.refresh();
        })
        .catch((err) => {
          this.$emit("createError", err);
          this.$refs.table.refresh();
        });
    },
  },

  mounted() {
    this.$root.$on(
      "data-table::filter",
      _.debounce((filters) => {
        this.additionalFilters = filters;
        this.$refs.table.refresh();
      }, 500)
    );
  },
  beforeDestroy() {
    this.$root.$off("data-table::filter");
  },
};
</script>

<style lang="scss" scoped>
.row.row-table {
  width: 100%;
}

input.inline {
  display: inline-block;
  width: auto;
  min-width: 200px;
}

.pagination {
  display: inline-flex;
  margin-right: 20px;
}

.new-button {
  display: flex;
  justify-content: flex-end;
  padding-right: 15px;

  .btn {
    padding: 0.375rem 1.5rem;
  }
}
</style>
