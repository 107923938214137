import _ from "lodash";
import moment from "moment";

export default {
  results(state) {
    if (!state.matches) return [];

    return _.reduce(
      state.matches,
      (result, item) => {
        if (!result[item.id_paese]) {
          result[item.id_paese] = {
            name: item.nome_paese,
            competitions: {},
          };
        }

        const ids = [];

        _.each(item.matches, (match) => {
          ids.push(match.id_competizione);

          if (!result[item.id_paese].competitions[match.id_competizione]) {
            result[item.id_paese].competitions[match.id_competizione] = {
              name: match.competizione,
              matches: [],
            };
          }

          result[item.id_paese].competitions[
            match.id_competizione
          ].matches.push({
            ...match,
            lat: item.lat,
            lng: item.lng,
          });
        });

        ids.forEach((id) => {
          result[item.id_paese].competitions[id].matches = _.sortBy(
            result[item.id_paese].competitions[id].matches,
            (match) => moment(match.data)
          );
        });

        return result;
      },
      {}
    );
  },
};
