export default {
  authenticated: false,
  user: {},

  checkLocalStorage() {
    if (localStorage.user) {
      this.user = JSON.parse(localStorage.user);
      this.authenticated = true;

      return true;
    } else {
      this.user = {};
      this.authenticated = false;

      return false;
    }
  },

  login(vm, creds, redirect, caller) {
    vm.$http
      .post("/login", creds)
      .then((response) => {
        localStorage.setItem("user", JSON.stringify(response.data));
        this.authenticated = true;
        this.user = response.data;

        // Redirect to a specified route
        if (redirect) {
          vm.$router.push(redirect);
          if (caller != undefined && typeof caller.resetErrors === "function")
            caller.resetErrors();
        } else {
          vm.$router.push("/");
          if (caller != undefined && typeof caller.resetErrors === "function")
            caller.resetErrors();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  },

  // To log out
  logout: function (vm) {
    vm.$http
      .get("/logout")
      .then(() => {
        localStorage.removeItem("user");
        this.user = {};
        this.authenticated = false;
        vm.$router.push("/login");
      })
      .catch(function (error) {
        console.log(error);
        localStorage.removeItem("user");
        this.user = {};
        this.authenticated = false;
        vm.$router.push("/login");
      });
  },

  isUserEnable(permesso) {
    if (this.user) {
      if (this.user.permessi.filter((e) => e.nome === permesso).length > 0) {
        return true;
      }
      for (var index in this.user.gruppi) {
        if (
          this.user.gruppi[index].permessi.filter((e) => e.nome === permesso)
            .length > 0
        ) {
          return true;
        }
      }
    }
    return false;
  },

  isAdmin() {
    if (this.user) {
      if (this.user.gruppi.filter((e) => e.id === 1).length > 0) {
        return true;
      }
    }
    return false;
  },

  isOsservCapo() {
    if (this.user) {
      if (this.user.gruppi.filter((e) => e.id === 2).length > 0) {
        return true;
      }
    }
    return false;
  },

  isVideoAnalyst() {
    if (this.user) {
      if (this.user.gruppi.filter((e) => e.id === 5).length > 0) {
        return true;
      }
    }
    return false;
  },
};
