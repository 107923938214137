<template>
  <div id="app" :class="[isInHome() && 'full-height']">
    <v-header
      @onLeftToggle="toggleLeftSidebar"
      @onRightToggle="toggleRightSidebar"
    />

    <router-view></router-view>

    <mission-modal />
  </div>
</template>

<script>
import { mapState } from "vuex";
import Header from "./Header.vue";
import MissionModal from "./MissionModal.vue";
import { isMobile } from "@/lib/utils";

export default {
  name: "Geomatch",
  components: {
    MissionModal,
    "v-header": Header,
  },

  computed: mapState("geomatch", [
    "leftSidebarCollapsed",
    "rightSidebarCollapsed",
  ]),

  methods: {
    toggleLeftSidebar() {
      this.$store.commit("geomatch/toggleLeftSidebar");
    },

    toggleRightSidebar() {
      this.$store.commit("geomatch/toggleRightSidebar");
    },

    isInHome() {
      return this.$route.path === "/geomatch";
    },
  },

  watch: {
    leftSidebarCollapsed() {
      if (this.leftSidebarCollapsed && this.rightSidebarCollapsed && isMobile) {
        this.$store.commit("toggleRightSidebar", false);
      }
    },

    rightSidebarCollapsed() {
      if (this.rightSidebarCollapsed && this.leftSidebarCollapsed && isMobile) {
        this.$store.commit("toggleLeftSidebar", false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#app {
  display: flex;
  flex-direction: column;

  &.full-height {
    height: 100vh;
  }
}

.wrapper {
  display: flex;
  flex: 1;
}
</style>
