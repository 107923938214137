var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"add-mission-modal",attrs:{"id":"add-mission-modal","title":"Aggiungi missione","centered":"","size":"lg"}},[(_vm.mission.match)?_c('b-row',[_c('b-col',[_c('h5',[_vm._v("Partita")]),_c('strong',[_vm._v(_vm._s(_vm.mission.match.casa)+" - "+_vm._s(_vm.mission.match.trasferta))]),_c('br'),_vm._v(" "+_vm._s(_vm.matchDateFormat(_vm.mission.match.data))+" ")]),_c('b-col',[_c('b-form-group',{attrs:{"label":"Intervallo di viaggio:"}},[_c('b-row',{staticClass:"mb-2"},[_c('b-col',{attrs:{"cols":"8"}},[_c('b-input-group',{attrs:{"prepend":"Dal"}},[_c('b-form-datepicker',{attrs:{"dark":true,"date-format-options":{
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                }},model:{value:(_vm.viaggio_dal_data),callback:function ($$v) {_vm.viaggio_dal_data=$$v},expression:"viaggio_dal_data"}})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-timepicker',{model:{value:(_vm.viaggio_dal_ora),callback:function ($$v) {_vm.viaggio_dal_ora=$$v},expression:"viaggio_dal_ora"}})],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"8"}},[_c('b-input-group',{attrs:{"prepend":"Al"}},[_c('b-form-datepicker',{attrs:{"dark":true,"date-format-options":{
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                }},model:{value:(_vm.viaggio_al_data),callback:function ($$v) {_vm.viaggio_al_data=$$v},expression:"viaggio_al_data"}})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-timepicker',{model:{value:(_vm.viaggio_al_ora),callback:function ($$v) {_vm.viaggio_al_ora=$$v},expression:"viaggio_al_ora"}})],1)],1)],1)],1)],1):_vm._e(),_c('b-row',{staticClass:"mt-3"},[_c('b-col',[_c('h5',[_vm._v("Osservatori")]),_c('b-form-checkbox-group',{attrs:{"options":_vm.osservatoriCheckbox},model:{value:(_vm.selectedOsservatori),callback:function ($$v) {_vm.selectedOsservatori=$$v},expression:"selectedOsservatori"}})],1)],1),_c('b-row',{staticClass:"mt-3"},[_c('b-col',[_c('h5',[_vm._v("Note")]),_c('b-form-textarea',{attrs:{"rows":"3"},model:{value:(_vm.notes),callback:function ($$v) {_vm.notes=$$v},expression:"notes"}})],1)],1),_c('b-button',{staticClass:"mt-3",attrs:{"type":"button","variant":"primary","disabled":_vm.disabled},on:{"click":_vm.aggiungiMissione}},[_vm._v("Salva")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }