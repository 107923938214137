<template>
  <div class="wrapper">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "GeomatchAdmin",
};
</script>

<style lang="scss" scoped>
.wrapper {
  flex-direction: column;
  padding: 20px;
}
</style>
