import axios from "axios";
import { pick, omit, get } from "lodash";
import { base64ToBlob } from "@/lib/utils";

export default {
  /**
   * Retrieve the data from the API
   *
   * @param {function} commit
   */
  retrieveData({ commit }) {
    axios
      .get("/")
      .then((res) => commit("updateData", res.data))
      .catch((err) => commit("updateError", err));
  },

  /**
   * Calls the API to save the new tag
   *
   * @param {Object} newTag
   * @param {Function} commit
   */
  saveTag({ state, commit, getters }, notifications) {
    commit("updateTagField", { key: "saving", value: true });

    commit("msgPush", { status: 1, msg: "Creo il tag..." }, { root: true });

    const path = state.newTag.editing ? `/${state.newTag.id}` : "";

    axios({
      method: state.newTag.editing ? "put" : "post",
      url: `/tag${path}`,
      data: {
        ...omit(state.newTag, ["skill_id", "saving", "saved", "editing"]),
        skill_id: state.newTag.skill ? state.newTag.skill.id : null,
        [state.newTag.team
          ? "team_id"
          : state.newTag.coach
          ? "coach_id"
          : "player_id"]: state.newTag.team
          ? state.newTag.team.id
          : state.newTag.coach
          ? state.newTag.coach.id
          : state.newTag.player.id,
        video_id: state.data.video.id,
        private_tag: state.privateTag ? 1 : 0,
      },
    })
      .then((res) => {
        const tag = res.data;

        if (res.data.result) {
          tag.result = res.data.result;
        }
        if (res.data.type) {
          tag.type = res.data.type;
        }
        if (res.data.skill_id) {
          tag.skill = getters.skills.find((s) => s.id === res.data.skill_id);
        }
        if (res.data.player_id) {
          const player = getters.players.find(
            (p) => p.id === res.data.player_id
          );
          tag.player = pick(player, ["id", "name"]);
        } else if (res.data.team_id) {
          const team = getters.teams.find((t) => t.id === res.data.team_id);
          tag.team = pick(team, ["id", "name"]);
        } else if (res.data.coach_id) {
          const coach = getters.coaches.find((c) => c.id === res.data.coach_id);
          tag.coach = pick(coach, ["id", "name"]);
        }
        commit("tagSaved", tag);
        commit("msgPush", { status: 1, msg: "Tag creato!" }, { root: true });
      })
      .catch((err) => {
        commit(
          "msgPush",
          {
            status: 0,
            msg: "Qualcosa è andato storto, prova a ricaricare la pagina",
          },
          { root: true }
        );
        commit("cancelNewTag");
      });
  },

  /**
   * Calls the API to save the new tag goal
   *
   * @param {Object} newTag
   * @param {Function} commit
   */
  saveTagGoal({ state, commit, getters }, notifications) {
    commit("updateTagField", { key: "saving", value: true });

    commit(
      "msgPush",
      { status: 1, msg: "Creo il tag goal..." },
      { root: true }
    );

    axios({
      method: "post",
      url: "/tag/goal",
      data: {
        ...omit(state.newTag, ["skill_id", "saving", "saved", "editing"]),
        skill_id: null,
        [state.newTag.team ? "team_id" : "player_id"]: state.newTag.team
          ? state.newTag.team.id
          : state.newTag.player.id,
        video_id: state.data.video.id,
      },
    })
      .then((res) => {
        const tag = res.data;

        if (res.data.result) {
          tag.result = res.data.result === "positive" ? "positive" : "negative";
        }
        if (res.data.type) {
          tag.type = res.data.type === "offensive" ? "offensive" : "defensive";
        }
        if (res.data.player_id) {
          const player = getters.players.find(
            (p) => p.id === res.data.player_id
          );
          tag.player = pick(player, ["id", "name"]);
        } else if (res.data.team_id) {
          const team = getters.teams.find((t) => t.id === res.data.team_id);
          tag.team = pick(team, ["id", "name"]);
        }
        commit("tagSaved", tag);
        commit(
          "msgPush",
          { status: 1, msg: "Tag goal creato!" },
          { root: true }
        );
        if (tag.assist) {
          const assist = tag.assist;
          if (tag.assist.result) {
            assist.result =
              tag.assist.result === "positive" ? "positive" : "negative";
          }
          if (tag.assist.type) {
            assist.type =
              tag.assist.type === "offensive" ? "offensive" : "defensive";
          }
          if (tag.assist.player_id) {
            const player = getters.players.find(
              (p) => p.id === tag.assist.player_id
            );
            assist.player = pick(player, ["id", "name"]);
          } else if (tag.assist.team_id) {
            const team = getters.teams.find((t) => t.id === tag.assist.team_id);
            assist.team = pick(team, ["id", "name"]);
          }
          commit("tagSaved", assist);
          commit(
            "msgPush",
            { status: 1, msg: "Tag assist creato!" },
            { root: true }
          );
        }
      })
      .catch((err) => {
        commit(
          "msgPush",
          {
            status: 0,
            msg: "Qualcosa è andato storto, prova a ricaricare la pagina",
          },
          { root: true }
        );
        commit("cancelNewTag");
      });
  },

  /**
   * Calls the API that deletes the tag
   *
   * @param {Object} commit
   * @param {Object} tag
   */
  deleteTag({ state, commit }, tag) {
    commit("msgPush", { status: 1, msg: "Cancello il tag..." }, { root: true });

    axios
      .delete(`/tag/${tag.id}`)
      .then((res) => {
        commit("deleteTag", res.data.id);

        if (Number(res.data.id) === get(state, "newTag.id")) {
          commit("cancelNewTag");
        }

        commit(
          "msgPush",
          { status: 1, msg: "Tag cancellato!" },
          { root: true }
        );
      })
      .catch((err) =>
        commit(
          "msgPush",
          { status: 0, msg: err.response.data.error },
          { root: true }
        )
      );
  },

  updateTag({ commit }, payload) {
    commit("msgPush", { status: 1, msg: "Aggiorno il tag..." }, { root: true });

    axios
      .put(`/tag/${payload.tag.id}`, payload.data)
      .then((res) => {
        commit("replaceTag", res.data);

        commit(
          "msgPush",
          { status: 1, msg: "Tag aggiornato!" },
          { root: true }
        );
      })
      .catch((err) =>
        commit(
          "msgPush",
          { status: 0, msg: err.response.data.error },
          { root: true }
        )
      );
  },

  toggleRecording({ state, commit }) {
    commit("toggleRecording");

    if (!state.isRecording && state.recording.start && state.recording.finish) {
      commit("updateTagField", {
        tag: {
          start: state.recording.start,
          end: state.recording.finish,
        },
      });

      commit("cancelRecording");
    }
  },

  shareTag({ commit }, payload) {
    commit(
      "msgPush",
      { status: 1, msg: "Condivido il tag..." },
      { root: true }
    );

    axios
      .post(`/tag/${payload.id}/share`, payload.people)
      .then(() =>
        commit("msgPush", { status: 1, msg: "Tag condiviso!" }, { root: true })
      )
      .catch((err) =>
        commit(
          "msgPush",
          { status: 0, msg: err.response.data.error },
          { root: true }
        )
      );
  },

  filterTags({ commit }, data) {
    commit(
      "msgPush",
      { status: 1, msg: "Applico i filtri..." },
      { root: true }
    );

    axios
      .post("/tag/search", data)
      .then((res) => {
        commit("setFilteredTags", res.data);
        commit(
          "msgPush",
          { status: 1, msg: "Filtri applicati!" },
          { root: true }
        );
      })
      .catch((err) =>
        commit(
          "msgPush",
          { status: 0, msg: err.response.data.error },
          { root: true }
        )
      );
  },

  export({ commit }, data) {
    commit(
      "msgPush",
      { status: 1, msg: "Preparo per l'esportazione..." },
      { root: true }
    );

    axios
      .post("/tag/export", data)
      .then(() =>
        commit(
          "msgPush",
          {
            status: 1,
            msg: "Tag esportati! Download in corso...",
          },
          { root: true }
        )
      )
      .catch((err) =>
        commit(
          "msgPush",
          { status: 0, msg: err.response.data.error },
          { root: true }
        )
      );
  },

  saveBookmark({ state, commit }, payload) {
    commit(
      "msgPush",
      { status: 1, msg: "Creo il segnalibro..." },
      { root: true }
    );

    const start = state.currentTime || 0;

    axios
      .post(
        "/bookmark",
        Object.assign({}, payload, { start, video_id: state.data.video.id })
      )
      .then((res) => {
        commit("bookmarkSaved", res.data);
        commit(
          "msgPush",
          { status: 1, msg: "Segnalibro creato!" },
          { root: true }
        );
      })
      .catch((err) =>
        commit(
          "msgPush",
          { status: 0, msg: err.response.data.error },
          { root: true }
        )
      );
  },

  deleteBookmark({ commit }, bookmarkId) {
    commit(
      "msgPush",
      { status: 1, msg: "Cancello il segnalibro..." },
      { root: true }
    );

    axios
      .delete(`/bookmark/${bookmarkId}`)
      .then(() => {
        commit("deleteBookmark", bookmarkId);
        commit(
          "msgPush",
          { status: 1, msg: "Segnalibro cancellato!" },
          { root: true }
        );
      })
      .catch((err) =>
        commit(
          "msgPush",
          { status: 0, msg: err.response.data.error },
          { root: true }
        )
      );
  },

  createSetting({ commit }, settingName) {
    commit(
      "msgPush",
      { status: 1, msg: "Salvo la nuova impostazione..." },
      { root: true }
    );

    axios
      .post("/setting", { name: settingName })
      .then((res) => {
        commit("createSetting", res.data);
        commit(
          "msgPush",
          { status: 1, msg: "Impostazione creata!" },
          { root: true }
        );
      })
      .catch((err) =>
        commit(
          "msgPush",
          { status: 0, msg: err.response.data.error },
          { root: true }
        )
      );
  },

  deleteSetting({ commit }, id) {
    commit(
      "msgPush",
      { status: 1, msg: "Cancello l'impostazione..." },
      { root: true }
    );

    axios
      .delete(`/setting/${id}`)
      .then(() => {
        commit("deleteSetting", id);
        commit(
          "msgPush",
          { status: 1, msg: "Impostazione cancellata!" },
          { root: true }
        );
      })
      .catch((err) =>
        commit(
          "msgPush",
          { status: 0, msg: err.response.data.error },
          { root: true }
        )
      );
  },

  updateSetting({ commit }, setting) {
    commit(
      "msgPush",
      { status: 1, msg: "Aggiorno l'impostazione..." },
      { root: true }
    );

    return axios
      .put(`/setting/${setting.id}`, setting)
      .then((res) => {
        commit("updateSetting", res.data);
        commit(
          "msgPush",
          { status: 1, msg: "Impostazione aggiornata!" },
          { root: true }
        );

        return res.data;
      })
      .catch((err) =>
        commit(
          "msgPush",
          { status: 0, msg: err.response.data.error },
          { root: true }
        )
      );
  },

  addEditor({ state, commit }) {
    const formData = new FormData();
    var time = state.currentTime;
    var roundTime = Math.round(time);
    formData.append("time", roundTime);
    formData.append("tagId", state.newTag.id);
    formData.append("seconds", 5);
    return axios.post("/tag/editor/add", formData).then((res) => {
      commit("editorAdded", res.data);
      commit(
        "msgPush",
        { status: 1, msg: "Editor creato con successo!" },
        { root: true }
      );
    });

    return null;
  },

  updateEditor({ state, commit }) {
    if (state.canvas.export && state.canvas.image) {
      const formData = new FormData();
      formData.append("canvas", state.canvas.export);
      formData.append("width", state.canvas.width);
      formData.append("height", state.canvas.height);
      const png = state.canvas.image.split(",")[1];
      const file = base64ToBlob(png, "image/png");
      formData.append("image", file);
      return axios
        .post(`/tag/editor/update/${state.canvas.id}`, formData)
        .then((res) => {
          commit("editorUpdated", res.data);
          commit(
            "msgPush",
            { status: 1, msg: "Editor salvato con successo!" },
            { root: true }
          );
        });
    }
    return null;
  },

  getEditor({ state, commit }, id) {
    return axios.get(`/tag/editor/${id}`).then((res) => {
      commit("editorUpdated", res.data);
    });
  },

  deleteEditor({ state, commit }, editor) {
    commit(
      "msgPush",
      { status: 1, msg: "Cancello l'editor..." },
      { root: true }
    );

    axios
      .delete(`/tag/editor/${editor.id}`)
      .then((res) => {
        commit("deleteEditor", res.data);

        if (Number(res.data.id) === get(state, "canvas.id")) {
          commit("setEditor", false);
        }

        commit(
          "msgPush",
          { status: 1, msg: "Editor cancellato!" },
          { root: true }
        );
      })
      .catch((err) =>
        commit(
          "msgPush",
          { status: 0, msg: err.response.data.error },
          { root: true }
        )
      );
  },

  downloadTag({ state, commit }, id) {
    return axios.get(`/tag/download/${id}`).then((res) => {
      commit(
        "msgPush",
        { status: 1, msg: "Il download della clip sarà disponibile a breve!" },
        { root: true }
      );
      //   commit("editorUpdated", res.data);
    });
  },
};
